import { render, staticRenderFns } from "./brand-owners-manage.vue?vue&type=template&id=76701830&scoped=true&"
import script from "./brand-owners-manage.vue?vue&type=script&lang=js&"
export * from "./brand-owners-manage.vue?vue&type=script&lang=js&"
import style0 from "./brand-owners-manage.vue?vue&type=style&index=0&id=76701830&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76701830",
  null
  
)

export default component.exports